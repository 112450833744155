@use "mq";

.person {
  &__content, 
  &__time {
    display: block;
  }

  &__content {
    font-size: 2em;

    @include mq.mq($from: small) {
      font-size: 3em;
    }

    a {
      cursor: url("../images/play-icon.svg") 15 15, pointer;
    }
  }

  &__time {
    margin-top: 0.5em;

    .minute {
      font-size: var(--font-size--small);
      vertical-align: top;
      position: relative;
      top: 0.05em;
    }
  }
}
