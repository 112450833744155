.navigation {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin-bottom: 1em;

  > * {
    grid-row: 1 / 2;
  }

  a {
    color: var(--color--fg);
    text-decoration: none;
    margin: -0.5em;
    padding: 0.5em;
  }

  &__home {
    grid-column: 2 / 3;
    text-align: center;
    font-size: var(--font-size--small);
    text-transform: uppercase;

    a {
      text-decoration-line: underline;
      text-decoration-style: dotted;
      text-decoration-thickness: 0.12em;
      text-decoration-color: black;
      transition-property: color;
      transition-duration: 500ms;

      &:hover {
        color: blue;
      }
    }

    &.back {
      &::before {
        content: "(";
      }
  
      &::after {
        content: ")";
      }
    }
  }

  &__prev, 
  &__next {
    width: min-content;
    line-height: var(--font-size--small);
  }

  &__prev {
    grid-column: 1 / 2;
    text-align: left;
    justify-self: start;

    &::before {
      content: "\2190";
    }
  }

  &__next {
    grid-column: 3 / 4;
    text-align: right;
    justify-self: end;
    
    &::before {
      content: "\2192";
    }
  }
}
