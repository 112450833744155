@use "mq";

body {
  color: var(--color--fg);
  background: var(--color--bg);
  font-size: 120%;
  line-height: var(--line-height);
  font-family: Helvetica, Helvetica Neue, Arial, sans-serif;
  overscroll-behavior: none;
  overflow-x: hidden;

  @include mq.mq($from: small, $until: large) {
    font-size: 100%;
  }

  @include mq.mq($from: large) {
    font-size: 150%;
  }
}

a {
  color: blue;
  text-decoration-line: underline;
  text-decoration-thickness: 0.075em;
  text-underline-offset: 0.12em;

  &.no-underline {
    text-decoration: none;
  }
}

h1 {
  display: none;
}

section {
  padding: 0.8em 1.2em;
}

.player {
  &__controls {
    flex-grow: 1;
    
    > * {
      display: block;
    }
  }
  
  &__info1,
  &__info2 {
    font-size: 0.9em;

    @include mq.mq($from: 500px, $until: small) {
      font-size: 1.2em;
    }

    @include mq.mq($from: small, $until: 1000px) {
      font-size: 1em;
    }

    @include mq.mq($from: 1000px) {
      font-size: 1.4em;
    }
  }

  &__info1 {
    &::after {
      content: "";
      display: block;
      height: 0.1em;
      width: 3em;
      max-width: 50%;
      background: #000;
      margin: 0.5em 0;
    }
  }
}

#content {
  > * + * {
    margin-top: 1em;
  }
}

.info {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  background: var(--color--bg);
  border-top: var(--border);
  border-bottom: var(--border);
  text-transform: uppercase;
  padding-top: var(--marquee-padding);
  padding-bottom: var(--marquee-padding);
  z-index: 50;

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }
}

.marquee {
  --gap: 4em;

  &__wrapper {
    gap: var(--gap);
    padding-left: var(--gap);

  }

  &__item {
    min-width: max-content;
  }
}

.intermission-button {
  color: red;
  text-transform: inherit;
  white-space: nowrap;
  padding-left: calc(var(--marquee-padding) * 2);
  padding-right: calc(var(--marquee-padding) * 2);

  &:not(.playing) {
    .wavy-text {
      > * {
        animation-name: unset;
      }
    }
  }

  &:hover {
    .wavy-text {
      > * {
        animation-name: wave;
      }
    }
  }

  &::before {
    background: red;
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    vertical-align: top;
    transition-duration: 500ms;
    margin-top: 0.065em;
  }
}

.lines {
  position: absolute;
  pointer-events: none;


  @include mq.mq($until: small) {
    top: calc(1.7em + 47vw);
    left: 0;
    right: 0;
    width: auto;
    height: 75vh;
    z-index: 10;
  }

  @include mq.mq($from: small) {
    top: 4vh;
    right: 0;
    bottom: 0;
    left: 10%;
    width: auto;
    height: auto;
    z-index: 40;
  }

  svg {
    --size: 70vw;

    @include mq.mq($from: small) {
      --size: 33vw;
    }

    position: absolute;
    overflow: visible;
    pointer-events: none;
    max-width: var(--size);
    max-height: var(--size);
    opacity: 0;

    top: max(calc(0 * var(--size)),calc(((100% - var(--size))/ 100) * var(--top)));
    left: max(calc(0 * var(--size)),calc(((100% - var(--size))/ 100) * var(--left)));

    animation-name: fadeIn;
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: both;

    > * {
      pointer-events: initial;
    }

    &:nth-child(1) {
      animation-delay: 100ms;
    }

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 300ms;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  } 
}

/*
@include mq.mq($from: medium) {
  #site-content {
    margin-left: auto;
    margin-right: auto;
    border-left: var(--border);
    border-right: var(--border);
    max-width: 90vw;
  }
}
*/
