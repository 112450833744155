.marquee {  
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    min-width: max-content;

    animation-name: scroll;
    animation-duration: var(--duration);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &--reverse {
    .marquee__wrapper {
      animation-direction: reverse;
    }
  }

  &:hover {
    .marquee__wrapper {
      animation-play-state: paused;
    }
  }

  &--paused {
    .marquee__wrapper {
      animation-play-state: paused;
    }

    &:hover {
      .marquee__wrapper {
        animation-play-state: running;
      }
    }
  }

  @keyframes scroll {
    0%   {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
}

@media (prefers-reduced-motion) {
  .marquee { 
    &__wrapper {
      animation-play-state: paused;
    }

    &:hover {
      .marquee__wrapper {
        animation-play-state: running;
      }
    }
  }
}
