.player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  audio {
    &:not([src="/assets/intermission.mp3"]) ~ .player__info2 {
      position: relative;
      text-indent: 1em;

      &::before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        box-sizing: border-box;
        border-style: solid;
        width: 0.6em;
        height: 0.76em;
        border-color: red;
        border-width: 0 0.2em 0 0.2em;
        top: 0.1em;
      }
    }
  }

  &.paused {
    audio ~ .player__info2 {
      &::before {
        top: 0.05em;
        width: 0.9em;
        border-width: 0.46em 0 0.46em 0.8em;
        border-color: transparent transparent transparent red;
      }
    }
  }
}
