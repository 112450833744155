:root {
  --color--fg: #000;
  --color--bg: #fff;

  --line-height: 1.2;
  --font-size--small: 0.7em;

  --border: 1px solid var(--color--fg);

  --marquee-padding: 0.2em;
}
