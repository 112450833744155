.wavy-text {
  > * {
    display: inline-block;
    animation: wave;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes wave {
    from   {
      transform: translate3d(0, -0.3em, 0);
    }

    50% {
      transform: translate3d(0, 0.5em, 0);
    }

    to {
      transform: translate3d(0, -0.3em, 0);
    }
  }
}
