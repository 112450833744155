@use "mq";

#site-content {
  min-height: 100vh;
  padding-top: calc(var(--line-height) * 1em + var(--marquee-padding) * 2);
  padding-bottom: calc(var(--line-height) * 1em + var(--marquee-padding) * 2);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;

  > * {
    grid-column-start: auto;
    grid-column-end: span 2;
  }

  @include mq.mq($from: small) {
    display: grid;
    align-items: stretch;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: auto 1fr;
    max-height: 100vh;
    overflow: hidden;

    #about, 
    #content {
      overflow-y: scroll;
      scrollbar-color: lightgray white;
    }
  }
}

.widgets {
  display: grid;
  grid-template-columns: 50% 50%;
  background: var(--color--bg);

  @include mq.mq($until: small) {
    position: sticky;
    top: calc(var(--marquee-padding) * 2 + 1.15rem);
    z-index: 35;
    grid-template-columns: 58% 42%;
  }
}

#clock, 
#player {
  grid-column-end: span 1;
  border-bottom: var(--border);

  &::-webkit-scrollbar {
    width: 0.5em;
    
  }
}

#clock {
  display: flex;
  padding: 1.5em;

  @include mq.mq($until: small) {
    border-left: var(--border);
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

#player {
  @include mq.mq($until: small) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  @include mq.mq($from: small) {
    border-left: var(--border);
  }
}

#content {
  border-bottom: var(--border);
  border-right: dotted 4px var(--color--fg);

  h3 {
    font-size: var(--font-size--small);
  }

  @include mq.mq($from: small) {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    border-left: var(--border);
  }
}

#about {
  h2 {
    font-size: var(--font-size--small);
    text-transform: uppercase;
  }

  > * + * {
    margin-top: 1rem;
  }

  > * + h2 {
    margin-top: 2rem;
  }
}

a.object-scatter {
  color: black;
  text-decoration-style: dotted;
  text-decoration-thickness: 0.12em;
  transition-property: color;
  transition-duration: 500ms;
  text-decoration-color: black;
  cursor: alias;

  &:hover {
    color: blue;
  }
}

.schedule {
  columns: 2;
  margin-top: 0.15em;
  margin-bottom: 1em;

  &__item {
    a {
      color: var(--color--fg);
      text-decoration: none;
      display: flex;
      width: 100%;
      overflow: hidden;
      gap: 1em;

      time {
        width: 2em;
        flex-shrink: 0;
      }

      .minute {
        font-size: var(--font-size--small);
        vertical-align: top;
        position: relative;
        top: 0.05em;
      }

      .name {
        transition-property: color;
        transition-duration: 500ms;
      }

      &:hover {
        .name {
          color: blue;
        }
      }
    }
  }
}
